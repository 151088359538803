import { useQuery } from '@tanstack/react-query';
import { SIM_FILTERS_INITIAL_VALUES } from 'atoms/filters/sim';
import { fetchRSimSimsProfiles } from 'rSimInventory/RSimApi/rSimApi';
import { fetchSimInventoryExtendedFilters } from 'simInventory/SimInventoryApi/simInventoryApi';
import { SimProfile } from 'simInventory/SimInventoryApi/simInventoryApi.interface';

export const useRSimsSims = <
  T extends { primaryIccid: string; secondaryIccid: string; euicc: string },
>(
  rsims?: T[],
) => {
  const { data } = useQuery({
    queryKey: ['RSimInventory', 'sims', rsims],
    queryFn: async () => {
      //Fetch configuration
      const { headers } = await fetchRSimSimsProfiles(rsims![0].euicc);

      const simProfilesIds = rsims!.reduce((sims, rsim) => {
        const profiles = [...sims];

        if (rsim.primaryIccid) {
          profiles.push(rsim.primaryIccid);
        }

        if (rsim.secondaryIccid) {
          profiles.push(rsim.secondaryIccid);
        }

        return profiles;
      }, [] as string[]);

      const simsProfiles = await fetchSimInventoryExtendedFilters(0, simProfilesIds.length, {
        ...SIM_FILTERS_INITIAL_VALUES,
        searchText: simProfilesIds,
        chosenIdType: 'ICCID',
      });

      const normalizedSims = simsProfiles.items.reduce((sims, item) => {
        return {
          ...sims,
          [item.iccid]: item,
        };
      }, {} as Record<string, any>);

      const normalizedRsimSim = rsims?.reduce((rsims, rsim) => {
        const rsimSims = [];
        if (normalizedSims[rsim.primaryIccid]) {
          rsimSims.push(normalizedSims[rsim.primaryIccid]);
        }

        if (normalizedSims[rsim.secondaryIccid]) {
          rsimSims.push(normalizedSims[rsim.secondaryIccid]);
        }
        return {
          ...rsims,
          [rsim.euicc]: rsimSims,
        };
      }, {} as Record<string, SimProfile[]>);

      return {
        headers,
        sims: normalizedRsimSim,
      };
    },
    enabled: !!rsims && rsims?.length > 0,
    gcTime: 0,
    // suspense: false,
  });

  return data;
};
