import { orderNumberFilterAtom } from 'atoms/filters';
import { rsimHintsAtomWithStorage, rsimOrderNumberHintsAtom } from 'atoms/hints';
import { HintedTextFieldFilters } from 'common/components/Filters/Inputs/HintedTextFieldFilters';
import { useTranslation } from 'react-i18next';

export const OrderNumberSearchInput = () => {
  const { t } = useTranslation();

  return (
    <HintedTextFieldFilters
      data-testid="orderNumber text field"
      placeholder={t('rSimInventory.orderNumberFilterPlaceholder')}
      filterAtom={orderNumberFilterAtom}
      filterHintsAtom={rsimOrderNumberHintsAtom}
      hintsAtomWithStorage={rsimHintsAtomWithStorage}
    />
  );
};
