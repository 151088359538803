import { useLazyPaginationQuery } from 'common/useLazyPaginationQuery';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import {
  fetchSimInventoryExtendedFilters,
  fetchSimInventoryExtendedFiltersPagination,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import { SimInventoryData } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { Sorting } from 'common/hooks/useSorting';
import { SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';
import { ID_SEARCH_REGEX } from 'common/components/Inputs/IdAdvanceSearch/MultipleIdInput';
import { ID_SEARCH_LIMIT } from 'simInventory/Components/Inputs/SimIdSearchInput';

export const useSimInventoryFetch = (
  filters: SimInventoryFiltersV2,
  sort: { columnName: string; sort: Sorting } | null = null,
  rowsPerPage: number,
  page: number,
) => {
  const { tags, searchText, chosenIdType, ...otherFilters } = filters;

  const { queryResult, pagination, queryKey } = useLazyPaginationQuery<SimInventoryData>(
    [
      'SimInventoryConfigurable',
      searchText,
      chosenIdType,
      sort,
      ...Object.values(otherFilters),
      tags,
      rowsPerPage,
      page,
    ],
    async ({ signal }) => {
      const results = searchText.match(ID_SEARCH_REGEX) || [''];
      const searchIds = results.slice(0, ID_SEARCH_LIMIT);
      return setMinimalPromiseTime(
        fetchSimInventoryExtendedFilters(
          page,
          rowsPerPage,
          {
            searchText: searchIds,
            chosenIdType: chosenIdType,
            tags,
            ...otherFilters,
          },
          sort,
          signal,
        ),
      );
    },
    async ({ signal }) => {
      const results = searchText.match(ID_SEARCH_REGEX) || [''];
      const searchIds = results.slice(0, ID_SEARCH_LIMIT);
      return fetchSimInventoryExtendedFiltersPagination(
        page,
        rowsPerPage,
        {
          searchText: searchIds,
          chosenIdType: chosenIdType,
          tags,
          ...otherFilters,
        },
        signal,
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const simInventory = queryResult.data;
  const isRefetching = queryResult.isRefetching;

  return {
    simInventory,
    pagination,
    queryKey,
    isRefetching,
  };
};
