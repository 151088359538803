import { useCallback, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import {
  PRODUCT_RSIM_TABLE_CONFIGURATION,
  RSIM_TABLE_CONFIGURATION,
} from './models/configurations';
import { KickRsimProvider } from './KickRsimProvider';
import { Routes } from 'Routes.constants';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRSimInventory } from 'rSimInventory/hooks/useRSimInventory';
import { Actions, Subjects } from 'permissions/ability';
import { ConfigurableTable } from 'common/components/ConfigurableTable/ConfigurableTable';
import { TextCell } from 'common/components/ConfigurableTable/Components/Cells/TextCell';
import { RSimSimTable } from './SimInventory/RSimSimTable';
import { DetailsPanel } from 'common/components/ConfigurableTable/Components/Containers/DetailsPanel';
import { RSimStatusCell } from './Cells/RSimStatusCell';
import { RSimEditDialog } from './Dialogs/RSimEditDialog';
import { RSimUpdateProvider } from './Providers/RSimUpdateProvider';
import { TagsCell } from './Cells/TagsCell';
import { RSimConfigurationDialog } from './Dialogs/RSimConfigurationDialog';
import { DurationCell } from 'common/components/ConfigurableTable/Components/Cells/DurationCell';
import { RSimsSimsProvider } from './Providers/RSimsSimsProvider';
import { OtaCell } from './Cells/OtaCell';
import { ID_SEARCH_LIMIT } from './Inputs/RSimIdSearchInput';
import { ID_SEARCH_REGEX } from '../../common/components/Inputs/IdAdvanceSearch/MultipleIdInput';
import { RSimEIDLinkCell } from './Cells/RSimEIDLinkCell';
import { useAtom, useAtomValue } from 'jotai';
import {
  pageFilterAtom,
  rowsPerPageFilterAtom,
  rsimFiltersAtom,
  rsimSoritngAtomWithStorage,
} from 'atoms/filters';
import { GridPaginationModel, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { ContentBox } from 'common/components/AppLayout/ContentBox';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import {
  TableLayoutActions,
  TableLayoutTable,
} from 'common/components/Layouts/TableLayout/TableLayout';
import { RefetchQueryButton } from 'common/components/Queries/RefetchQueryButton';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { ToggleActivationDialog } from 'rSimInventory/Inventory/Components/Actions/ToggleActivationDialog';
import { Action } from 'common/components/ConfigurableTable/models/configurableTable';

const CustumizedConfigurationTable = styled(ConfigurableTable)({
  '& .MuiDataGrid-columnHeader[data-field="rSimStatus"] .MuiTypography-text3': {
    textTransform: 'none',
  },
});

const RSIM_CELL_CONFIGURATION = {
  default: TextCell,
  euicc: RSimEIDLinkCell,
  rSimStatus: RSimStatusCell,
  tags: TagsCell,
  appliedOtaConfigurationProfile: OtaCell,
  inMinuteUsage: DurationCell,
};

const getDetailPanelContent = (props: GridRowParams) => {
  return (
    <DetailsPanel>
      <RSimSimTable
        euicc={props.row.euicc}
        primaryIccid={props.row.primaryIccid}
        secondaryIccid={props.row.secondaryIccid}
        status={props.row.status}
      />
    </DetailsPanel>
  );
};
const getDetailPanelHeight = () => 176;

export const RSimTableContent = () => {
  const filters = useAtomValue(rsimFiltersAtom);

  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageFilterAtom);
  const [page, setPage] = useAtom(pageFilterAtom);

  const {
    searchText,
    chosenIdType,
    lastActiveProfile,
    lastUpdateTime,
    tags,
    connectionId,
    orderNumber,
    simulateOutage,
    accounts,
    eidFrom,
    eidTo,
  } = filters;

  const [sorting, setSorting] = useAtom(rsimSoritngAtomWithStorage);

  const searchIds = useMemo(() => {
    const results = searchText.match(ID_SEARCH_REGEX) || [''];
    return results.slice(0, ID_SEARCH_LIMIT);
  }, [searchText]);

  const {
    queryResult,
    pagination: { totalNumberOfItems },
    queryKey,
  } = useRSimInventory({
    searchIds: searchIds,
    searchText,
    chosenIdType,
    lastActiveProfile,
    lastUpdateTime,
    tags,
    connectionId,
    orderNumber,
    simulateOutage,
    accounts,
    eidFrom,
    eidTo,
    sorting,
    rowsPerPage,
    page,
  });

  const euiccs =
    useMemo(() => queryResult.data?.items.map((item) => item.euicc), [queryResult.data]) || [];

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const onSortModelChange = useCallback((model: GridSortModel) => {
    const [sortField] = model;
    //temporary solution
    if (sortField.field === 'euicc') {
      setSorting({
        columnName: 'EID',
        //@ts-ignore
        sort: sortField.sort,
      });
    } else {
      setSorting({
        columnName: sortField.field,
        //@ts-ignore
        sort: sortField.sort,
      });
    }
  }, []);

  const onPaginationChange = useCallback(
    (pagination: GridPaginationModel) => {
      if (pagination.page !== page) {
        setPage(pagination.page);
      }

      if (pagination.pageSize !== rowsPerPage) {
        setRowsPerPage(pagination.pageSize);
      }
    },
    [page, rowsPerPage, setPage, setRowsPerPage],
  );

  const productsViewEnabled = useHasFeatureFlag('ProductsView');
  const productsViewRsimActivationEnabled =
    useHasFeatureFlag('ProductsViewRsimActivation') && productsViewEnabled;

  const actions = useMemo(() => {
    const menuActions: Action[] = [
      {
        id: 'viewDetails',
        label: 'View Details',
        type: 'custom',
        actionCallback: (data: any) => {
          navigate(
            `${productsViewEnabled ? Routes.productsRsim : Routes.rSimInventory}/${data.euicc}`,
            {
              state: {
                previousPath: `${pathname}${search}`,
              },
            },
          );
        },
      },
      {
        id: 'toggleActivation',
        label: 'Activate',
        type: 'dialog',
        Component: ToggleActivationDialog,
        enabled: (data) => {
          return !!data.connectionId;
        },
      },
      {
        id: 'edit',
        label: 'Edit',
        type: 'dialog',
        Component: RSimEditDialog,
        permission: {
          action: Actions.edit,
          subject: Subjects.tags,
        },
      },
      {
        id: 'otaConfiguration',
        label: t('rSimInventory.configurationDetails'),
        type: 'dialog',
        Component: RSimConfigurationDialog,
        permission: {
          action: Actions.edit,
          subject: Subjects.otaConfiguration,
        },
      },
    ];

    return menuActions.filter(
      (actions) => actions.id !== 'toggleActivation' || productsViewRsimActivationEnabled,
    );
  }, [pathname, search]);

  return (
    <KickRsimProvider euiccs={euiccs}>
      <RSimUpdateProvider queryKey={queryKey}>
        <RSimsSimsProvider rsims={queryResult.data?.items}>
          <CustumizedConfigurationTable
            disableColumnReorder
            actions={actions}
            data-testid="rsims inventory table"
            configuration={
              productsViewEnabled ? PRODUCT_RSIM_TABLE_CONFIGURATION : RSIM_TABLE_CONFIGURATION
            }
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            rows={queryResult.data?.items || []}
            cellConfiguration={RSIM_CELL_CONFIGURATION}
            totalNumberOfItems={totalNumberOfItems}
            onPaginationChange={onPaginationChange}
            page={page}
            pageSize={rowsPerPage}
            onSortModelChange={onSortModelChange}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'euicc',
                    sort: sorting?.sort,
                  },
                ],
              },
            }}
          />
        </RSimsSimsProvider>
      </RSimUpdateProvider>
    </KickRsimProvider>
  );
};

export const RSimTable = () => (
  <>
    <TableLayoutActions>
      <RefetchQueryButton queryKey={['RSimInventory']} />
    </TableLayoutActions>
    <TableLayoutTable>
      <SuspenseLoadingView
        loadFallback={
          <ContentBox>
            <TableSkeleton showStartSkeleton numberOfRows={20} />
          </ContentBox>
        }
        queryKey={['RSimInventory']}
      >
        <RSimTableContent />
        <Outlet />
      </SuspenseLoadingView>
    </TableLayoutTable>
  </>
);
