import { HydrateAtoms } from 'atoms/components/HydrateAtoms';
import { simFiltersAtom } from 'atoms/filters/sim';
import { simHintsAtom, simHintsAtomWithStorage } from 'atoms/hints/sim';
import { Provider, useAtomValue } from 'jotai';
import { ReactNode } from 'react';

export const SimFiltersDialogAtomInitializator = ({ children }: { children: ReactNode }) => {
  const filters = useAtomValue(simFiltersAtom);
  const hitns = useAtomValue(simHintsAtomWithStorage);

  return (
    <Provider>
      <HydrateAtoms
        initialValues={[
          [simFiltersAtom, filters],
          [simHintsAtom, hitns],
          [simHintsAtomWithStorage, hitns],
        ]}
      >
        {children}
      </HydrateAtoms>
    </Provider>
  );
};
