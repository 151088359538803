import { Suspense, useCallback, useState } from 'react';
import { FiltersButton } from 'common/components/Filters/FiltersButton';

import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import Box from '@mui/material/Box';
import {
  TabBar,
  TabBarActions,
  TabBarChips,
  TabBarFilters,
} from 'common/components/AppLayout/TabBar/TabBar';
import Grid from '@mui/material/Grid2';
import { RefetchQueryButton } from 'common/components/Queries/RefetchQueryButton';
import { useHasActiveFilters } from './hooks/hooks/useHasActiveFilters';
import { simProductsFiltersAtom } from 'atoms/filters/sim-products';
import { simProductsHintsAtomWithStorage } from 'atoms/hints/sim-products';
import { SimProductsFiltersDialogAtomInitializer } from './Atoms/SimFiltersDialogAtomInitializator';

import { SimInventoryFilterChips } from 'simInventory/Filters/Components/Chips/SimInventoryFilterChips';
import { IdSearchInput } from 'simInventory/Filters/Components/Inputs/IdSearchInput';
import { SimProductsFiltersDialog } from './SimProductsFiltersDialog';

export const SimProductsFilters = () => {
  const [open, setOpen] = useState(false);

  const setFilters = useSetAtom(simProductsFiltersAtom);
  const resetFilters = useResetAtom(simProductsFiltersAtom);

  const setHints = useSetAtom(simProductsHintsAtomWithStorage);

  const onApply = useCallback((filters: any, hints: any) => {
    setFilters(filters);
    setHints(hints);
    setOpen(false);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const hasChips = useHasActiveFilters();
  return (
    <TabBar>
      <Grid container rowGap={4} sx={{ mt: 0, width: '100%' }} justifyContent={'space-between'}>
        <TabBarFilters>
          <Box sx={{ flex: 1, maxWidth: 360, mr: 4 }}>
            <IdSearchInput />
          </Box>
          <Suspense>
            <div data-testid="sims products search menu">
              <FiltersButton
                onClick={() => {
                  setOpen(true);
                }}
                active={false}
              />
              {open && (
                <SimProductsFiltersDialogAtomInitializer>
                  <SimProductsFiltersDialog
                    onApply={onApply}
                    onCancel={onClose}
                    onClear={resetFilters}
                    onClose={onClose}
                  />
                </SimProductsFiltersDialogAtomInitializer>
              )}
            </div>
          </Suspense>
        </TabBarFilters>
        <TabBarActions>
          <RefetchQueryButton queryKey={['SimProductsConfigurable']} />
        </TabBarActions>
      </Grid>
      <TabBarChips>{hasChips ? <SimInventoryFilterChips /> : null}</TabBarChips>
    </TabBar>
  );
};
