import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

export const simProductsHintsAtom = atom<{
  accounts: any;
  tags: any;
  mobileNetworkOperator: any;
  connectionId: string[];
  orderNumber: string[];
  label: string[];
}>({
  accounts: [],
  tags: [],
  mobileNetworkOperator: [],
  connectionId: [],
  orderNumber: [],
  label: [],
});

export const simProductsTagsHintsAtom = focusAtom(simProductsHintsAtom, (optic) =>
  optic.prop('tags'),
);

export const simProductsAccountsHintsAtom = focusAtom(simProductsHintsAtom, (optic) =>
  optic.prop('accounts'),
);

export const simProductsConnectionIdHintsAtom = focusAtom(simProductsHintsAtom, (optic) =>
  optic.prop('connectionId'),
);

export const simProductsOrderNumberHintsAtom = focusAtom(simProductsHintsAtom, (optic) =>
  optic.prop('orderNumber'),
);

export const labelHintsAtom = focusAtom(simProductsHintsAtom, (optic) => optic.prop('label'));

export const mnoHintsAtom = focusAtom(simProductsHintsAtom, (optic) =>
  optic.prop('mobileNetworkOperator'),
);

const atomLocalStorage = createJSONStorage(() => localStorage);

export const simProductsHintsAtomWithStorage = atomWithStorage(
  'sim-products-hints',
  {
    accounts: [],
    tags: [],
    connectionId: [],
    orderNumber: [],
    label: [],
  },
  atomLocalStorage,
);
