import { ConfirmationDialog } from 'common/components/Dialog/ConfirmationDialog';

type ToggleActivationDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const ToggleActivationDialog = ({ open, onClose }: ToggleActivationDialogProps) => {
  return (
    <ConfirmationDialog
      message="Activate rSIM EID: 89758163017310969665443804"
      title="Activation"
      open={open}
      onConfirm={onClose}
      onCancel={onClose}
    />
  );
};
