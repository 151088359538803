import { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useTranslation } from 'react-i18next';
import { TagContext } from 'tags/TagProvider';
import { SetStateAction, useAtom, useSetAtom, WritableAtom } from 'jotai';

type TagsSearchInputProps = {
  tagsFilterAtom: WritableAtom<string[], [SetStateAction<string[]>], void>;
  tagsHintsAtom: WritableAtom<any, [any], void>;
  hintsAtomWithStorage: WritableAtom<any, [any], void>;
};

export const TagsSearchFilter = ({
  tagsFilterAtom,
  tagsHintsAtom,
  hintsAtomWithStorage,
}: TagsSearchInputProps) => {
  const { t } = useTranslation();
  const [tags, setTags] = useAtom(tagsFilterAtom);
  const setTagHints = useSetAtom(tagsHintsAtom);
  const [hints, setHitnsWithStorage] = useAtom(hintsAtomWithStorage);

  const { type, fetchTags } = useContext(TagContext);
  const { data: tagsData, isFetched } = useQuery({
    queryKey: type === 'sim' ? ['simTags'] : ['rsimTags'],
    queryFn: async () => {
      const tags = await fetchTags();
      return tags.map((tag) => ({
        ...tag,
        toAdd: false,
      }));
    },
  });

  useEffect(() => {
    if (!isFetched) {
      return;
    }
    //@ts-ignore
    const validTagsHints = hints?.tags.filter((tag) => {
      return tagsData?.some((validTag) => validTag.id === tag.id);
    });
    setHitnsWithStorage((hints: any) => ({
      ...hints,
      tags: validTagsHints,
    }));
  }, [tagsData, isFetched]);

  return (
    <AutocompleteSelect
      testId="tags-multiselect"
      //@ts-ignore
      hints={hints?.tags || []}
      values={tags}
      options={tagsData || []}
      onChange={(values) => {
        setTags(values.map((value) => value.id));
        //@ts-ignore
        setTagHints(values);
      }}
      onRemoveHint={(hint: any) => {
        setHitnsWithStorage((hints: any) => ({
          ...hints,
          tags: hints.tags.filter((currentHint: any) => {
            return currentHint.id !== hint.id;
          }),
        }));
      }}
      allowAdding={false}
      placeholder={t('rSimInventory.tagsFilterPlaceholder')}
    />
  );
};
