import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { ValueDropdownOption } from './ValueDropdownOption';
import { OptionType } from '../tableCells/editCells/ValueDropdownCell.interface';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { Trans, useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import { ScrollArea } from '../../../common/components/ScrollArea';
import { Button } from 'common/components/Buttons/Button';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import { palette } from 'theme/palette';

const NewOptionTextField = styled(TextField)(() => ({
  '& .MuiInputLabel-root': {
    top: '-6px',
  },
  '& .MuiInputLabel-shrink': {
    top: 0,
  },
  '& .MuiOutlinedInput-notchedOutline span': {
    paddingLeft: '20px',
  },
}));

const CounterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '10px',
  '& .textfield-error': {
    color: palette.error.contrastText,
  },
}) as typeof Box;

const NEW_OPTION_MAX_LENGTH = 64;

export const ValueDropdownModal = ({
  open,
  onClose,
  onUpdateOptions,
  ...props
}:
  | {
      open: boolean;
      onClose: () => void;
      onUpdateOptions: (options: OptionType[]) => void;
    } & GridRenderCellParams) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<OptionType[] | []>(props.value);
  const [newOptionValue, setNewOptionValue] = useState<string>('');
  const [optionToRemove, setOptionToRemove] = useState<OptionType | null>(null);

  const addOptionToRemove = (optionToRemove: OptionType) => {
    setOptionToRemove(optionToRemove);
  };

  const closeRemoveOption = () => {
    setOptionToRemove(null);
  };

  const removeOptionHandler = () => {
    setOptions((prevOptions) => {
      return prevOptions.filter((option) => option !== optionToRemove);
    });
    closeRemoveOption();
  };

  const addNewOptionHandler = () => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions.unshift({ id: 0, label: newOptionValue.trimEnd(), order: 0 });
      return newOptions;
    });
    setNewOptionValue('');
  };

  const updateOptionsHandler = () => {
    onUpdateOptions(options.map((option: OptionType, index) => ({ ...option, order: index })));
  };

  const onChangeLabelHandler = (optionIndex: number, newLabel: string) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[optionIndex].label = newLabel;
      return newOptions;
    });
  };

  const newOptionExistError =
    !!options.length &&
    options.some((option) => option.label.toLowerCase() === newOptionValue.toLowerCase());

  const handleDrop = (optionToChange: OptionType, newIndex: number) => {
    setOptions((prevOptions) => {
      const parsedOptions: OptionType[] = [];

      prevOptions.forEach((option, index) => {
        if (option.label === optionToChange.label) {
          if (index === newIndex) {
            parsedOptions.push({ ...option, order: index });
          }
        } else {
          if (index === newIndex) {
            parsedOptions.push({ ...optionToChange, order: index });
            parsedOptions.push({ ...option, order: index });
          } else {
            parsedOptions.push({ ...option, order: index });
          }
        }
      });

      return parsedOptions;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOptions([]);
        onClose();
      }}
      disableEscapeKeyDown
      keepMounted={true}
    >
      <DialogTitle>
        <Typography variant={'h3'}>
          {optionToRemove
            ? t('customFields.dropdownModal.removeTitle')
            : t('customFields.dropdownModal.title')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ height: optionToRemove ? '100px' : '500px', width: '500px' }}>
        {!optionToRemove ? (
          <>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                if (!options.map((o) => o.label).includes(newOptionValue)) {
                  addNewOptionHandler();
                }
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box sx={{ pt: 2, height: '60px', flexGrow: 1 }}>
                  <NewOptionTextField
                    autoComplete={'off'}
                    error={newOptionExistError}
                    helperText={
                      newOptionExistError ? t('customFields.dropdownModal.valueNameTaken') : ''
                    }
                    key={'addNewValueTextField'}
                    id={'addNewValueTextField'}
                    fullWidth
                    value={newOptionValue}
                    onChange={(e) => setNewOptionValue(e.target.value.trimStart())}
                    label={t('customFields.dropdownModal.textFieldLabel')}
                    slotProps={{ htmlInput: { maxLength: NEW_OPTION_MAX_LENGTH } }}
                  />
                  <CounterBox component="span">
                    <Typography
                      variant={'text5'}
                      color="text.secondary"
                      className={`${
                        (newOptionValue as string)?.length === NEW_OPTION_MAX_LENGTH
                          ? 'textfield-error'
                          : ''
                      }`}
                    >{`${
                      (newOptionValue as string)?.length || 0
                    } / ${NEW_OPTION_MAX_LENGTH}`}</Typography>
                  </CounterBox>
                </Box>
                <Box sx={{ pt: 2, ml: 5, height: '60px' }}>
                  <Button
                    variant={'primary'}
                    iconStart={<AddIcon />}
                    onClick={addNewOptionHandler}
                    disabled={newOptionExistError || !newOptionValue}
                    size="medium"
                    aria-label={'Add new Option'}
                  >
                    {t('common.add')}
                  </Button>
                </Box>
              </Box>
            </form>
            <Divider orientation="horizontal" flexItem sx={{ mt: 2, mb: 5 }} />
            <Box sx={{ height: '370px', width: '105%' }}>
              <ScrollArea>
                <Box sx={{ mr: 5 }}>
                  <>
                    {newOptionValue && !newOptionExistError && (
                      <Typography
                        sx={{ ml: 4 }}
                        variant="text1"
                        color="text.secondary"
                        data-testid="fromLabel"
                      >
                        {t('customFields.dropdownModal.confirmNewValue')}
                      </Typography>
                    )}
                    {options?.length > 0 &&
                      [...options]
                        .sort((first, second) => first.order - second.order)
                        .filter((option) =>
                          option.label.toLowerCase().includes(newOptionValue.toLowerCase()),
                        )
                        .map((option: OptionType, optionIndex) => {
                          return (
                            <Box key={option.label}>
                              <ValueDropdownOption
                                option={option}
                                options={options}
                                optionIndex={optionIndex}
                                onRemoveOptionHandler={addOptionToRemove}
                                onChangeLabelHandler={onChangeLabelHandler}
                                handleDrop={handleDrop}
                              />
                            </Box>
                          );
                        })}
                  </>
                </Box>
              </ScrollArea>
            </Box>
          </>
        ) : (
          <>
            <Trans
              i18nKey={'customFields.dropdownModal.removeDescription'}
              values={{
                label: optionToRemove!.label,
              }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={() => {
            optionToRemove ? closeRemoveOption() : onClose();
          }}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant={'primary'}
          onClick={() => {
            !optionToRemove && updateOptionsHandler();
            optionToRemove ? removeOptionHandler() : onClose();
          }}
        >
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
