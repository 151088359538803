import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

export const rsimHintsAtom = atom<{
  accounts: any;
  tags: any;
  connectionId: string[];
  orderNumber: string[];
}>({
  accounts: [],
  tags: [],
  connectionId: [],
  orderNumber: [],
});

export const rsimTagsHintsAtom = focusAtom(rsimHintsAtom, (optic) => optic.prop('tags'));

export const rsimAccountsHintsAtom = focusAtom(rsimHintsAtom, (optic) => optic.prop('accounts'));

export const rSimConnectionIdHintsAtom = focusAtom(rsimHintsAtom, (optic) =>
  optic.prop('connectionId'),
);

export const rsimOrderNumberHintsAtom = focusAtom(rsimHintsAtom, (optic) =>
  optic.prop('orderNumber'),
);

const atomLocalStorage = createJSONStorage(() => localStorage);
export const rsimHintsAtomWithStorage = atomWithStorage(
  'rsim-hints',
  {
    accounts: [],
    tags: [],
    connectionId: [],
    orderNumber: [],
  },
  atomLocalStorage,
);
