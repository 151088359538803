import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useDebounce } from 'common/useDebounce';
import { useTranslation } from 'react-i18next';
import { SetStateAction, useAtom, useSetAtom, WritableAtom } from 'jotai';
import { fetchAccounts } from 'rSimInventory/Filters/services/accountsApi';

const MAX_ACCOUNTS_TO_FILTER = 10;

type AccountSearchFilterProps = {
  accountsFilterAtom: WritableAtom<string[], [SetStateAction<string[]>], void>;
  accountsHintsAtom: WritableAtom<any, [any], void>;
  hintsAtomWithStorage: WritableAtom<any, [any], void>;
};

export const AccountSearchFilter = ({
  accountsFilterAtom,
  accountsHintsAtom,
  hintsAtomWithStorage,
}: AccountSearchFilterProps) => {
  const { t } = useTranslation();

  const setAccountHints = useSetAtom(accountsHintsAtom);

  const [hints, setHitnsWithStorage] = useAtom(hintsAtomWithStorage);

  const [accountSearch, setAccountSearch] = useState('');
  const debounceAccount = useDebounce(accountSearch, 500);

  const [accounts, setAccounts] = useAtom(accountsFilterAtom);

  const { data: accountsData, isFetched } = useQuery({
    queryKey: ['accounts', debounceAccount],
    queryFn: async () => {
      const accounts = await fetchAccounts(debounceAccount);

      const accountOptions = accounts.map((account) => ({
        id: account.accountRef.toString(),
        name: account.accountName,
        toAdd: false,
        showBothIdAndName: true,
      }));

      return accountOptions;
    },
    initialData: [],
  });

  const { errors, warnings } = useMemo(() => {
    let errors: string[] = [];
    let warnings: string[] = [];
    if (accounts && accounts.length >= MAX_ACCOUNTS_TO_FILTER + 1) {
      errors = [t('common.limitReached')];
      warnings = [];
    } else if (accounts && accounts.length >= MAX_ACCOUNTS_TO_FILTER) {
      errors = [];
      warnings = [t('common.limitReached')];
    }

    return { errors, warnings };
  }, [accounts]);

  return (
    <AutocompleteSelect
      testId="accounts-multiselect"
      //@ts-ignore
      hints={hints?.accounts || []}
      errors={errors}
      warnings={warnings}
      values={accounts}
      options={accountsData!}
      onChange={(values) => {
        setAccounts(values.map((value) => value.id));
        //@ts-ignore
        setAccountHints(values);
      }}
      onRemoveHint={(hint: any) => {
        setHitnsWithStorage((hints: any) => ({
          ...hints,
          accounts: hints.accounts.filter((currentHint: any) => {
            return currentHint.id !== hint.id;
          }),
        }));
      }}
      allowAdding={false}
      inputValue={accountSearch}
      onTextInputChange={(val) => {
        setAccountSearch(val);
      }}
      allowFilteringById={true}
      placeholder={t('rSimInventory.accountFilterPlaceholder')}
      isLoading={!isFetched}
    />
  );
};
