import { TextFieldWithHints } from 'common/components/Inputs/TextFieldWithHints/TextFieldWithHints';
import { SetStateAction, useAtom, useSetAtom, WritableAtom } from 'jotai';

type HintedTextFieldFiltersProps = {
  filterAtom: WritableAtom<string, [SetStateAction<string>], void>;
  filterHintsAtom: WritableAtom<any, [any], void>;
  hintsAtomWithStorage: WritableAtom<any, [any], void>;
  'data-testid'?: string;
  placeholder?: string;
};

export const HintedTextFieldFilters = ({
  filterAtom,
  filterHintsAtom,
  hintsAtomWithStorage,
  ...otherProps
}: HintedTextFieldFiltersProps) => {
  const [filterValue, setFilterValue] = useAtom(filterAtom);

  const [filterHints, setFilterHints] = useAtom(filterHintsAtom);

  const setHitnsWithStorage = useSetAtom(hintsAtomWithStorage);

  return (
    <TextFieldWithHints
      {...otherProps}
      hints={filterHints}
      value={filterValue}
      onChange={(value) => {
        setFilterValue(value || '');
      }}
      onRemoveHint={(hint: string) => {
        setFilterHints((currentHints: any) =>
          currentHints.filter((currentHint: any) => currentHint !== hint),
        );
        setHitnsWithStorage((hints: any) => ({
          ...hints,
          connectionId: hints.connectionId.filter((currentHint: any) => {
            return currentHint !== hint;
          }),
        }));
      }}
    />
  );
};
