import { tagsFilterAtom } from 'atoms/filters/sim';
import { simHintsAtomWithStorage, simTagsHintsAtom } from 'atoms/hints/sim';
import { TagsSearchFilter } from 'common/components/Filters/Inputs/TagsSearchFilter';

export const TagsSearchInput = () => {
  return (
    <TagsSearchFilter
      tagsFilterAtom={tagsFilterAtom}
      tagsHintsAtom={simTagsHintsAtom}
      hintsAtomWithStorage={simHintsAtomWithStorage}
    />
  );
};
