import { accountsFilterAtom } from 'atoms/filters';
import { rsimAccountsHintsAtom, rsimHintsAtomWithStorage } from 'atoms/hints';
import { AccountSearchFilter } from 'common/components/Filters/Inputs/AccountSearchFilter';

export const AccountSearchInput = () => {
  return (
    <AccountSearchFilter
      accountsFilterAtom={accountsFilterAtom}
      accountsHintsAtom={rsimAccountsHintsAtom}
      hintsAtomWithStorage={rsimHintsAtomWithStorage}
    />
  );
};
