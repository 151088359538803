import { IdRangeSearch } from 'common/components/Inputs/IdRangeSearch/IdRangeSearch';

export const IccidRangeSearch = () => {
  return (
    <IdRangeSearch
      from=""
      to={''}
      onChange={function (from: string, to: string): void {
        throw new Error('Function not implemented.');
      }}
    />
  );
};
