import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

export const simHintsAtom = atom<{
  accounts: any;
  tags: any;
  mobileNetworkOperator: any;
  connectionId: string[];
  orderNumber: string[];
  label: string[];
}>({
  accounts: [],
  tags: [],
  mobileNetworkOperator: [],
  connectionId: [],
  orderNumber: [],
  label: [],
});

export const simTagsHintsAtom = focusAtom(simHintsAtom, (optic) => optic.prop('tags'));

export const simAccountsHintsAtom = focusAtom(simHintsAtom, (optic) => optic.prop('accounts'));

export const simConnectionIdHintsAtom = focusAtom(simHintsAtom, (optic) =>
  optic.prop('connectionId'),
);

export const simOrderNumberHintsAtom = focusAtom(simHintsAtom, (optic) =>
  optic.prop('orderNumber'),
);

export const labelHintsAtom = focusAtom(simHintsAtom, (optic) => optic.prop('label'));

export const mnoHintsAtom = focusAtom(simHintsAtom, (optic) => optic.prop('mobileNetworkOperator'));

const atomLocalStorage = createJSONStorage(() => localStorage);
export const simHintsAtomWithStorage = atomWithStorage(
  'sim-hints',
  {
    accounts: [],
    tags: [],
    connectionId: [],
    orderNumber: [],
    label: [],
    mobileNetworkOperator: [],
  },
  atomLocalStorage,
);
