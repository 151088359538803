import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import React, { useCallback, useState } from 'react';
import { simInventoryDetailsScrollArea } from './SimInventory.style';
import Stack from '@mui/material/Stack';
import { SimInventoryEditDialog } from './SimInventoryEditDialog';
import { Button } from 'common/components/Buttons/Button';
import { SimInventoryDetails } from './SimInventoryDetails';
import { useSimDetails } from '../hooks/useSimDetails';
import { ScrollArea } from '../../common/components/ScrollArea';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';

export type SimInventoryEntryRoutingParams = {
  iccid: string;
};

export const SimInventoryDetailsPage = () => {
  const { iccid } = useParams<SimInventoryEntryRoutingParams>();
  const { refetch } = useSimDetails(iccid!);

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const handleOnClose = useCallback(() => {
    setEditDialogOpen(false);
    refetch();
  }, []);

  const handleTagsUpdate = useCallback(() => {
    refetch();
  }, [refetch]);

  const ability = useAbility();
  const editingSimAvailable =
    ability.can(Actions.edit, Subjects.tags) || ability.can(Actions.edit, Subjects.customFields);
  return (
    <Stack sx={simInventoryDetailsScrollArea}>
      <Box flex={0} sx={{ display: 'flex', justifyContent: 'end', pt: 2, pr: 6 }}>
        {editingSimAvailable && (
          <Button
            variant="secondary"
            onClick={() => {
              setEditDialogOpen(true);
            }}
          >
            Edit
          </Button>
        )}
      </Box>
      <Box flex={1} sx={{ height: '100%' }}>
        <ScrollArea>
          <SimInventoryDetails iccid={iccid} />
        </ScrollArea>
      </Box>
      <SimInventoryEditDialog
        open={editDialogOpen}
        onClose={handleOnClose}
        iccid={iccid!}
        onTagsUpdate={handleTagsUpdate}
      />
    </Stack>
  );
};
