import { accountsFilterAtom } from 'atoms/filters/sim';
import { simAccountsHintsAtom, simHintsAtomWithStorage } from 'atoms/hints/sim';
import { AccountSearchFilter } from 'common/components/Filters/Inputs/AccountSearchFilter';

export const AccountSearchInput = () => {
  return (
    <AccountSearchFilter
      accountsFilterAtom={accountsFilterAtom}
      accountsHintsAtom={simAccountsHintsAtom}
      hintsAtomWithStorage={simHintsAtomWithStorage}
    />
  );
};
