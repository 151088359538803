import { useAtom, useSetAtom } from 'jotai';
import {
  chosenIdTypeFilterAtom,
  iccidRangeFilterAtom,
  searchTextFilterAtom,
} from 'atoms/filters/sim';
import { useCallback } from 'react';
import { SimIdSearchInput } from 'simInventory/Components/Inputs/SimIdSearchInput';

export const IdSearchInput = () => {
  const [chosenIdType, setChosenIdType] = useAtom(chosenIdTypeFilterAtom);

  const [searchText, setSearchText] = useAtom(searchTextFilterAtom);

  const setIccidRange = useSetAtom(iccidRangeFilterAtom);

  const onChange = useCallback((value: string) => {
    setSearchText(value);
    if (value !== '') {
      setIccidRange({ iccidFrom: '', iccidTo: '' });
    }
  }, []);

  return (
    <SimIdSearchInput
      chosenId={chosenIdType}
      onChosenIdChange={setChosenIdType}
      search={searchText}
      onSearchChange={onChange}
    />
  );
};
