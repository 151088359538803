import { tagsFilterAtom } from 'atoms/filters';
import { rsimHintsAtomWithStorage, rsimTagsHintsAtom } from 'atoms/hints';
import { TagsSearchFilter } from 'common/components/Filters/Inputs/TagsSearchFilter';

export const TagsSearchInput = () => {
  return (
    <TagsSearchFilter
      tagsFilterAtom={tagsFilterAtom}
      tagsHintsAtom={rsimTagsHintsAtom}
      hintsAtomWithStorage={rsimHintsAtomWithStorage}
    />
  );
};
