import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { BulkProvisioningJobsDropTable } from './Components/BulkProvisioningJobsDropTable';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import { BulkProvisioningUploadButton } from './Components/BulkProvisioningUploadButton';
import React, { useEffect, useMemo, useState } from 'react';
import { TableWithFiltersLayout } from 'common/components/AppLayout/TableWithFiltersLayout';
import { cleanFalsyEntries } from 'common/utils/objectUtils';
import { FiltersModal } from './Filters/ProvisioningFiltersModal';

import { provisioningFilters } from './BulkProvisioning.interface';
import { bulkProvisioningFileNameFilter } from './BulkProvisioningDataTestIds';

import { BulkFiltersChips } from './Filters/ProvisioningFiltersChips';
import { INITIAL_FILTERS } from './BulkProvisioning.constants';
import { parseSourceFilter, parseStatusFilter } from './BulkProvisioning.utils';
import { parseResponseDateToIso } from 'common/utils/parseResponseDate';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import {
  TabBar,
  TabBarActions,
  TabBarChips,
  TabBarFilters,
} from 'common/components/AppLayout/TabBar/TabBar';
import Box from '@mui/material/Box';
import { RefetchQueryButton } from 'common/components/Queries/RefetchQueryButton';
import MenuItem from '@mui/material/MenuItem';
import { Select } from '../common/components/Inputs/Select/Select';
import { useTranslation } from 'react-i18next';
import { AutoHideSnackbar } from '../common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from '../common/Snackbar/AlertTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {
  downloadBulkTemplate,
  isTemplateType,
  templates,
  TemplateType,
} from './BulkProvisioningApi/bulkProvisioningApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthError } from '../auth/utils';
import { endOfDay } from 'date-fns';
import Grid from '@mui/material/Grid';
import { TextField } from 'common/components/Inputs/TextField/TextField';

type ExportError = {
  title: string;
  description: string;
};

export const BulkProvisioningJobs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [fileName, setFileName] = useState(searchParams.get('fileName') || '');

  const updateSearchParams = (updateObject: any) => {
    const currentSearchParams = Object.fromEntries(searchParams.entries());
    const updatedSearchParams = {
      ...currentSearchParams,
      ...updateObject,
    };
    setSearchParams(cleanFalsyEntries(updatedSearchParams), { replace: true });
  };

  const filters = useMemo(() => {
    try {
      return provisioningFilters.parse({
        status: parseStatusFilter(searchParams.get('status')),
        orderNumber: searchParams.get('orderNumber') || '',
        uploadDateFrom: parseResponseDateToIso(searchParams.get('uploadDateFrom'), null),
        uploadDateTo: parseResponseDateToIso(searchParams.get('uploadDateTo'), null),
        source: parseSourceFilter(searchParams.get('source')),
      });
    } catch (err) {
      updateSearchParams(INITIAL_FILTERS);
      return INITIAL_FILTERS;
    }
  }, [searchParams]);

  const { t } = useTranslation();

  const [exportError, setExportError] = useState<ExportError | null>(null);

  const queryClient = useQueryClient();
  const { mutateAsync: downloadTemplate, isPending: isDownloading } = useMutation({
    mutationKey: ['BulkProvisioningJobs', 'downloadTemplate'],
    mutationFn: (selectedTemplate: TemplateType) => {
      return downloadBulkTemplate(selectedTemplate);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['BulkProvisioningJobs', 'downloadTemplate'] });
    },
    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      } else {
        setExportError({
          title: t('common.requestFailed'),
          description: t('common.somethingWrong'),
        });
      }
    },
  });

  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);
  useEffect(() => {
    if (isDownloading) {
      setDownloadingSnackbarOpened(true);
    }
  }, [isDownloading]);

  const getTemplateName = (template: TemplateType) => {
    if (template === 'MasterTemplate') {
      return t('bulkProvisioning.masterTemplate');
    }
    if (template === 'BulkProvisioning') {
      return t('bulkProvisioning.bulkProvisioning');
    }
    if (template === 'UnitDataAdjustment') {
      return t('bulkProvisioning.unitDataAdjustment');
    }
  };

  const ability = useAbility();
  return ability.can(Actions.read, Subjects.bulkProvisioning) ? (
    <TableWithFiltersLayout
      filters={
        <TabBar>
          <Grid container rowGap={4} sx={{ mt: 0 }} justifyContent={'space-between'}>
            <TabBarFilters>
              <Box sx={{ maxWidth: '322px', flex: 1 }}>
                <TextField
                  data-testid={bulkProvisioningFileNameFilter}
                  value={fileName}
                  onChange={(event) => setFileName(event.target.value)}
                  label={t('common.fileName')}
                  showSearchIcon={true}
                />
              </Box>
              <FiltersModal
                //@ts-ignore
                filters={filters}
                onFiltersChange={(filters) => {
                  const isValidRange =
                    filters.uploadDateFrom &&
                    filters.uploadDateTo &&
                    endOfDay(filters.uploadDateTo) > filters.uploadDateFrom;
                  updateSearchParams({
                    ...filters,
                    uploadDateFrom:
                      filters.uploadDateFrom && isValidRange
                        ? filters.uploadDateFrom.toISOString()
                        : null,
                    uploadDateTo:
                      filters.uploadDateTo && isValidRange
                        ? endOfDay(filters.uploadDateTo).toISOString()
                        : null,
                  });
                }}
              />
            </TabBarFilters>
            <TabBarActions>
              <RefetchQueryButton queryKey={['BulkProvisioningJobs']} />
              <Select
                name={t('bulkProvisioning.downloadTemplate')}
                //@ts-ignore
                displayEmpty
                //@ts-ignore
                renderValue={() => t('bulkProvisioning.downloadTemplate')}
                fullWidth={false}
                data-testid="downloadTemplate"
                sx={{ marginRight: '1rem', width: '190px' }}
                variant={'filled'}
                onChange={(event) => {
                  if (event.target.value && isTemplateType(event.target.value)) {
                    downloadTemplate(event.target.value);
                  }
                }}
                defaultValue={null}
                value={null}
              >
                {templates.map((template) => (
                  <MenuItem key={template} value={template}>
                    {getTemplateName(template)}
                  </MenuItem>
                ))}
              </Select>
              <SimInventoryCan I={Actions.upload} a={Subjects.bulkProvisioning}>
                <BulkProvisioningUploadButton />
              </SimInventoryCan>
            </TabBarActions>
          </Grid>
          <TabBarChips>
            <BulkFiltersChips
              filters={filters}
              onFilterClear={(key: any) => {
                if (key === 'uploadDate') {
                  updateSearchParams({ uploadDateFrom: '', uploadDateTo: '' });
                } else {
                  updateSearchParams({ [key]: '' });
                }
              }}
              onFiltersClear={() => {
                updateSearchParams(INITIAL_FILTERS);
              }}
            />
          </TabBarChips>
        </TabBar>
      }
    >
      <SimInventoryCan I={Actions.read} a={Subjects.bulkProvisioning}>
        <SuspenseLoadingView
          loadFallback={<TableSkeleton showEndSkeleton />}
          queryKey={['BulkProvisioningJobs']}
        >
          <BulkProvisioningJobsDropTable searchProps={{ ...filters, fileName }} />
          <Outlet />
        </SuspenseLoadingView>
      </SimInventoryCan>

      <Stack>
        <AutoHideSnackbar
          severity="success"
          open={downloadingSnackbarOpened}
          onClose={() => {
            setDownloadingSnackbarOpened(false);
          }}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle> {t('common.downloading')}!</AlertTitle>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          open={!!exportError}
          severity="error"
          onClose={() => setExportError(null)}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{exportError?.title || ''}!</AlertTitle>
          <Typography variant="text1" color="text.white">
            {exportError?.description}
          </Typography>
        </AutoHideSnackbar>
      </Stack>
    </TableWithFiltersLayout>
  ) : (
    <Navigate replace to="/" />
  );
};
