import { connectionIdFilterAtom } from 'atoms/filters';
import { rSimConnectionIdHintsAtom, rsimHintsAtomWithStorage } from 'atoms/hints';
import { HintedTextFieldFilters } from 'common/components/Filters/Inputs/HintedTextFieldFilters';
import { useTranslation } from 'react-i18next';

export const ConnectionIdSearchInput = () => {
  const { t } = useTranslation();
  return (
    <HintedTextFieldFilters
      data-testid="connectionId text field"
      placeholder={t('rSimInventory.connectionIdFilterPlaceholder')}
      filterAtom={connectionIdFilterAtom}
      filterHintsAtom={rSimConnectionIdHintsAtom}
      hintsAtomWithStorage={rsimHintsAtomWithStorage}
    />
  );
};
