import { useCallback, useState } from 'react';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { FilterSection } from 'common/components/Filters/FilterSection';
import { FiltersDialog, FiltersDialogProps } from 'common/components/Filters/FiltersDialog';
import { FilterListItem } from 'common/components/Filters/FilterListItem';
import Box from '@mui/material/Box';
import { FilterBox } from 'common/components/Filters/FilterBox';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { RadioSection } from 'common/components/Filters/IdFilterSelector/RadioSection';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useQuery } from '@tanstack/react-query';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { useAbility } from 'permissions/hooks/useAbility';
import { simProductsHintsAtom, simProductsHintsAtomWithStorage } from 'atoms/hints/sim-products';
import { simProductsFiltersAtom } from 'atoms/filters/sim-products';
import { IdSearchInput } from 'simInventory/Filters/Components/Inputs/IdSearchInput';
import { IccidRangeSearch } from 'simInventory/Components/Inputs/IccidRangeSearch';
import { SessionStatusInput } from 'simInventory/Filters/Components/Inputs/SessionStatusInput';
import { AccountSearchInput } from 'simInventory/Filters/Components/Inputs/AccountSearchInput';
import { MnoSearchInput } from 'simInventory/Filters/Components/Inputs/MNOSearchInput';
import { LabelSearchInput } from 'simInventory/Filters/Components/Inputs/LabelSearchInput';
import { TagsSearchInput } from 'simInventory/Filters/Components/Inputs/TagsSearchInput';
import { ConnectionIdSearchInput } from 'simInventory/Filters/Components/Inputs/ConnectionIdSearchInput';
import { OrderNumberSearchInput } from 'simInventory/Filters/Components/Inputs/OrderNumberSearchInput';
import { CustomFieldInputs } from 'simInventory/Filters/Components/Inputs/CutomFieldInputs';
import { uniqBy } from 'lodash';

type SimFiltersDialogProps = Pick<FiltersDialogProps, 'onCancel' | 'onClear' | 'onClose'> & {
  onApply: (filters: any, hints: any) => void;
};

export const SimProductsFiltersDialog = ({ onApply, onClear, ...props }: SimFiltersDialogProps) => {
  const { t } = useTranslation();

  const filters = useAtomValue(simProductsFiltersAtom);
  const resetFilters = useResetAtom(simProductsFiltersAtom);

  const [selectedSearch, setSelectedSearch] = useState<'search' | 'iccidRange'>(() => {
    if (filters.iccidFrom !== '' || filters.iccidTo !== '') {
      return 'iccidRange';
    }

    return 'search';
  });

  const hints = useAtomValue(simProductsHintsAtom);
  const storedHints = useAtomValue(simProductsHintsAtomWithStorage);

  const onClearClicked = useCallback(() => {
    resetFilters();
    onClear();
  }, []);

  const onApplyClicked = useCallback(() => {
    const connectionIdHintToAdd = filters.connectionId;
    const newConnectionIdHints = [...hints.connectionId];
    if (connectionIdHintToAdd && !newConnectionIdHints.includes(connectionIdHintToAdd)) {
      newConnectionIdHints.push(connectionIdHintToAdd);
    }

    const orderNumberHintToAdd = filters.orderNumber;
    const newOrderNumberHints = [...hints.orderNumber];
    if (orderNumberHintToAdd && !newOrderNumberHints.includes(orderNumberHintToAdd)) {
      newOrderNumberHints.push(orderNumberHintToAdd);
    }

    const labelHintToAdd = filters.label;
    const newLabelHints = [...hints.label];
    if (labelHintToAdd && !newLabelHints.includes(labelHintToAdd)) {
      newLabelHints.push(labelHintToAdd);
    }

    const hintsWithTextfields = {
      ...hints,
      //@ts-ignore
      accounts: uniqBy([...storedHints.accounts, ...hints.accounts], (account) => account.id),
      //@ts-ignore
      tags: uniqBy([...storedHints.tags, ...hints.tags], (tag) => tag.id),
      //@ts-ignore
      mobileNetworkOperator: uniqBy(
        //@ts-ignore
        [...storedHints.mobileNetworkOperator, ...hints.mobileNetworkOperator],
        (mno) => mno.id,
      ),
      connectionId: newConnectionIdHints,
      orderNumber: newOrderNumberHints,
      label: newLabelHints,
    };

    if (parseInt(filters.iccidFrom) > parseInt(filters.iccidTo)) {
      onApply({ ...filters, iccidFrom: '', iccidTo: '' }, hintsWithTextfields);
    } else {
      onApply(filters, hintsWithTextfields);
    }
  }, [filters, hints]);

  const ability = useAbility();

  const customFieldsEnabled = useHasFeatureFlag('CustomFields'); //&& ability.can(Actions.edit, Subjects.customFields);

  const { data: customFieldsData } = useQuery<any>({
    queryKey: ['customFields'],
    enabled: false, //It will use previous fetched custom fields
  });
  const customFieldsAvailable =
    customFieldsEnabled &&
    customFieldsData?.length > 0 &&
    ability.can(Actions.edit, Subjects.customFields);

  return (
    <FiltersDialog
      {...props}
      open={true}
      onApply={onApplyClicked}
      onClear={onClearClicked}
      oneColumn={!customFieldsAvailable}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <FilterSection title={t('common.idSearch')}>
          <FilterBox>
            <RadioSection
              options={['search', 'iccidRange']}
              value={selectedSearch}
              onValueChange={(newValue) => setSelectedSearch(newValue as 'search' | 'iccidRange')}
              labels={{
                search: 'Search',
                iccidRange: 'ICCID Range',
              }}
            >
              {(value) => {
                return (
                  <Box>
                    <Box
                      data-selector="search"
                      sx={{ opacity: value === 'search' ? 1 : 0 }}
                      aria-hidden={value !== 'search'}
                    >
                      <IdSearchInput />
                    </Box>
                    <Box
                      data-selector="iccidRange"
                      sx={{ opacity: value === 'iccidRange' ? 1 : 0 }}
                      aria-hidden={value !== 'iccidRange'}
                    >
                      <IccidRangeSearch />
                    </Box>
                  </Box>
                );
              }}
            </RadioSection>
          </FilterBox>
        </FilterSection>
        <FilterSection title={t('simInventory.sim')}>
          <List>
            <FilterListItem label={t('simInventory.sessionStatus')} htmlFor="select status">
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <SessionStatusInput />
              </Box>
            </FilterListItem>
            <SimInventoryCan I={Actions.filter} a={Subjects.internalData}>
              <FilterListItem label="Account" htmlFor="account">
                <AccountSearchInput />
              </FilterListItem>
            </SimInventoryCan>
            <FilterListItem
              label={t('simInventory.mobileNetworkOperator')}
              htmlFor="mobileNetworkOperator"
            >
              <MnoSearchInput />
            </FilterListItem>
            <FilterListItem label={t('common.label')} htmlFor="label">
              <LabelSearchInput />
            </FilterListItem>
            <FilterListItem label={'Tag'} htmlFor="tag">
              <TagsSearchInput />
            </FilterListItem>
          </List>
        </FilterSection>
        <FilterSection title={t('common.order')}>
          <List>
            <FilterListItem label={t('common.connectionId')} htmlFor="connectionId">
              <ConnectionIdSearchInput />
            </FilterListItem>
            <FilterListItem label={t('simInventory.orderNumber')} htmlFor="orderNumber">
              <OrderNumberSearchInput />
            </FilterListItem>
          </List>
        </FilterSection>
      </Box>
      {customFieldsAvailable && (
        <FilterSection title={t('customFields.customFields')}>
          <List>
            <CustomFieldInputs />
          </List>
        </FilterSection>
      )}
    </FiltersDialog>
  );
};
