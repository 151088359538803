import { HydrateAtoms } from 'atoms/components/HydrateAtoms';
import { Provider, useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { simProductsFiltersAtom } from 'atoms/filters/sim-products';
import { simProductsHintsAtom, simProductsHintsAtomWithStorage } from 'atoms/hints/sim-products';

export const SimProductsFiltersDialogAtomInitializer = ({ children }: { children: ReactNode }) => {
  const filters = useAtomValue(simProductsFiltersAtom);
  const hitns = useAtomValue(simProductsHintsAtomWithStorage);

  return (
    <Provider>
      <HydrateAtoms
        initialValues={[
          [simProductsFiltersAtom, filters],
          [simProductsHintsAtom, hitns],
          [simProductsHintsAtomWithStorage, hitns],
        ]}
      >
        {children}
      </HydrateAtoms>
    </Provider>
  );
};
