import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import {
  fetchSimInventoryExtendedFiltersPagination,
  MaxSimProfilesCountError,
  ReportAlreadyScheduledError,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import { AuthError } from 'auth/utils';
import { AutoHideSnackbar } from 'common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from 'common/Snackbar/AlertTitle';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  checkIsLargeReport,
  downloadSimProfilesReportWithBackgroundSupport,
} from './api/simProfileReportsApi';
import { LargeExportPrompt } from './LargeExportPrompt';
import { useAtomValue } from 'jotai';
import { simFiltersWithSplitedSearchIdAtom, simSortingAtomWithStorage } from 'atoms/filters/sim';

type ExportError = {
  title: string;
  description: string[];
};

export const SimProfileDownloadReportsExtendedFiltersButtonLargeExport: React.FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const filters = useAtomValue(simFiltersWithSplitedSearchIdAtom);
  const { tags, searchIds, chosenIdType, ...otherFilters } = filters;

  const sorting = useAtomValue(simSortingAtomWithStorage);

  const [confirmationRequire, setConfirmationRequire] = useState(false);
  const [largeExport, setLargeExport] = useState(false);

  const query = useQuery({
    queryKey: [
      'SimInventoryConfigurable',
      searchIds,
      chosenIdType,
      sorting,
      ...Object.values(otherFilters),
      tags,
    ],
    queryFn: async ({ signal }) => {
      const data = await fetchSimInventoryExtendedFiltersPagination(
        0,
        100,
        {
          searchText: searchIds,
          chosenIdType: chosenIdType,
          tags,
          ...otherFilters,
        },
        signal,
      );
      return data.totalNumberOfItems || 0;
    },
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [
      'sim-inventory',
      'reports',
      'download',
      {
        searchText: searchIds,
        chosenIdType: chosenIdType,
        tags,
        ...otherFilters,
      },
      sorting,
    ],
    mutationFn: async () => {
      await downloadSimProfilesReportWithBackgroundSupport(
        {
          searchText: searchIds,
          chosenIdType: chosenIdType,
          tags,
          ...otherFilters,
        },
        sorting,
      );
    },
    retry: 0,
    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      }

      if (error instanceof MaxSimProfilesCountError) {
        setExportError({
          title: t('common.exportFailed'),
          description: [
            t('simInventory.maxSimProfilesCountError', {
              maxSimProfiles: error.maxSimProfilesCount,
            }),
          ],
        });
      } else if (error instanceof ReportAlreadyScheduledError) {
        setExportError({
          title: t('common.exportFailed'),
          description: [
            t('simInventory.anotherBackgroundExportIsStillInProgress'),
            t('simInventory.pleaseTryAgainLater'),
          ],
        });
      } else {
        setExportError({
          title: t('common.requestFailed'),
          description: [t('common.somethingWrong')],
        });
      }
    },
    onSuccess: () => {
      setDownloadingSnackbarOpened(true);
    },
  });

  const exportReport = async () => {
    const largeExport = await checkIsLargeReport({
      searchText: searchIds,
      chosenIdType: chosenIdType,
      tags,
      ...otherFilters,
    });

    setLargeExport(largeExport);
    setConfirmationRequire(largeExport);
    if (!largeExport) {
      mutate();
    }
  };

  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);

  const { t } = useTranslation();

  const [exportError, setExportError] = useState<ExportError | null>(null);

  return (
    <>
      <Stack>
        <Button
          startIcon={<FileDownloadIcon />}
          disabled={disabled || isPending}
          color="secondary"
          onClick={exportReport}
        >
          {t('common.export')}
        </Button>
        <LargeExportPrompt
          open={confirmationRequire}
          numberOfCards={query.data || 0}
          onConfirm={async () => {
            mutate();
            setConfirmationRequire(false);
          }}
          onClose={() => {
            setConfirmationRequire(false);
            setLargeExport(false);
          }}
        />
        <AutoHideSnackbar
          severity="success"
          open={downloadingSnackbarOpened && !largeExport}
          onClose={() => {
            setDownloadingSnackbarOpened(false);
          }}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle> {t('common.downloading')}!</AlertTitle>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          severity="success"
          open={downloadingSnackbarOpened && largeExport}
          onClose={() => {
            setDownloadingSnackbarOpened(false);
            setLargeExport(false);
          }}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{`Exporting ${query.data} SIMs`}</AlertTitle>
          <Typography variant="text1" color="text.white">
            You'll receive an email with a download link when it's ready
          </Typography>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          open={!!exportError}
          severity="error"
          onClose={() => setExportError(null)}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{exportError?.title || ''}!</AlertTitle>
          {exportError?.description.map((d, index) => (
            <div key={index}>
              <Typography variant="text1" color="text.white">
                {d}
              </Typography>
            </div>
          ))}
        </AutoHideSnackbar>
      </Stack>
    </>
  );
};
