import { rsimFiltersAtom } from 'atoms/filters';
import { rsimHintsAtom, rsimHintsAtomWithStorage } from 'atoms/hints';
import { Provider, useAtomValue } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { ReactNode } from 'react';

export const RSimTemporaryHydrateAtoms = ({ initialValues, children }: any) => {
  useHydrateAtoms(initialValues);
  return children;
};

export const RSimFiltersDialogAtomInitializator = ({ children }: { children: ReactNode }) => {
  const filters = useAtomValue(rsimFiltersAtom);
  const hitns = useAtomValue(rsimHintsAtomWithStorage);

  return (
    <Provider>
      <RSimTemporaryHydrateAtoms
        initialValues={[
          [rsimFiltersAtom, filters],
          [rsimHintsAtom, hitns],
          [rsimHintsAtomWithStorage, hitns],
        ]}
      >
        {children}
      </RSimTemporaryHydrateAtoms>
    </Provider>
  );
};
